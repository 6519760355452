<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      "Bank Account": "Conta Bancária",
      Save: "Salvar",
    },
    es: {
      "Bank Account": "Cuenta Bancaria",
      Save: "Salvar",
    },
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      alert: {
        type: "",
        message: "",
      },

      loading: false,

      name: "",
      cellphone: "",
      email: "",
      builder: "",
      enterprise: "",
    };
  },
  validations: {
    name: { required },
    cellphone: { required },
    email: {},
    builder: {},
    enterprise: {},
  },
  methods: {
    createClient() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        api
          .post("clients", {
            name: this.name,
            cellphone: this.cellphone,
            email: this.email,
            builder: this.builder,
            enterprise: this.enterprise,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$v.$reset();
              this.$router.push('/clients');
            } else {
              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
            }

            this.loading = false;
          });
      }
    },
  },
  mounted() {},
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xl-7">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">Clientes</h4>
        </div>
        <div v-if="alert.message" :class="'alert ' + alert.type">
          {{ t(alert.message) }}
        </div>
        <div class="card">
          <div class="card-body table-responsive">
            <b-form class="p-3" @submit.prevent="createClient">
              <b-form-group
                id="name"
                :label="t('Nome')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  type="text"
                  :class="{ 'is-invalid': $v.name.$error }"
                ></b-form-input>
                <div v-if="$v.name.$error" class="invalid-feedback">
                  <span v-if="!$v.name.required">{{
                    t("O nome é obrigatório.")
                  }}</span>
                </div>
              </b-form-group>
              <b-form-group
                id="cellphone"
                :label="t('Celular')"
                label-for="cellphone"
              >
                <b-form-input
                  id="cellphone"
                  v-model="cellphone"
                  type="text"
                  :class="{ 'is-invalid': $v.cellphone.$error }"
                  v-mask="'(##) #####-####'"
                ></b-form-input>
                <div v-if="$v.cellphone.$error" class="invalid-feedback">
                  <span v-if="!$v.cellphone.required">{{
                    t("O celular é obrigatório.")
                  }}</span>
                </div>
              </b-form-group>
              <b-form-group
                id="email"
                :label="t('E-mail')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  type="text"
                  :class="{ 'is-invalid': $v.email.$error }"
                ></b-form-input>
                <div v-if="$v.email.$error" class="invalid-feedback">
                  <span v-if="!$v.email.required">{{
                    t("O e-mail é obrigatório.")
                  }}</span>
                </div>
              </b-form-group>
              <b-form-group
                id="builder"
                :label="t('Construtora')"
                label-for="builder"
              >
                <b-form-input
                  id="builder"
                  v-model="builder"
                  type="text"
                  :class="{ 'is-invalid': $v.builder.$error }"
                ></b-form-input>
                <div v-if="$v.builder.$error" class="invalid-feedback">
                  <span v-if="!$v.builder.required">{{
                    t("O nome é obrigatório.")
                  }}</span>
                </div>
              </b-form-group>
              <b-form-group
                id="enterprise"
                :label="t('Empreendimento')"
                label-for="enterprise"
              >
                <b-form-input
                  id="enterprise"
                  v-model="enterprise"
                  type="text"
                  :class="{ 'is-invalid': $v.enterprise.$error }"
                ></b-form-input>
                <div v-if="$v.enterprise.$error" class="invalid-feedback">
                  <span v-if="!$v.enterprise.required">{{
                    t("O nome é obrigatório.")
                  }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="
                    this.loading == true || !this.name || !this.cellphone
                  "
                  type="submit"
                  variant="default"
                >
                  Adicionar
                  <b-spinner
                    v-if="loading"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
